import React from "react"
import localforage from "localforage"
import {
  ReactVideoPlayer,
  ReactAudioPlayer,
} from "../../components/reactPlayer"
import { getBlobURL } from "../../functions/utils"

async function permToResetAnxietyFixture() {
  const localCollection = await localforage.getItem("cG9zdDo3MjM0")
  if (!localCollection) return undefined

  const fixture = [
    {
      programName: "Permission to Reset Anxiety",
      type: "permission-reset-anxiety",
      featuredImage: "/app/images/quantum-touch/morning-meditation.jpg",
      audioFiles: [
        {
          title: "Reset Anxiety Introduction",
          image:
            "/app/images/permission-to-reset-anxiety/reset-anxiety-instructions.jpg",
          showTabs: "true",
          content: [
            {
              audio: [
                {
                  title: "Introduction",
                  audioMp3: (
                    <ReactAudioPlayer
                      audioURL={
                        getBlobURL(
                          localCollection.audio?.introductionToAnxiety,
                          "audio"
                        ) ||
                        "https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/anxiety/instructions/introduction-for-anxiety.mp3"
                      }
                      title="Anxiety : Introduction"
                    />
                  ),
                },
                {
                  title: "How does Permissioning help with Anxiety",
                  audioMp3: (
                    <ReactAudioPlayer
                      audioURL={
                        getBlobURL(
                          localCollection.audio?.permissioningHelpAnxiety,
                          "audio"
                        ) ||
                        "https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/anxiety/instructions/how-does-pat-help-with-anxiety.mp3"
                      }
                      title="Anxiety : How does Permissioning help with Anxiety"
                    />
                  ),
                },
              ],
            },
          ],
        },
        {
          title: "Hand to heart",
          image: "/app/images/permission-to-sleep/hand-heart.jpg",
          showTabs: false,
          content: [
            {
              audio: [
                {
                  title: "Long version",
                  audioMp3: (
                    <ReactAudioPlayer
                      audioURL={
                        getBlobURL(
                          localCollection.audio?.handToHeartLong,
                          "audio"
                        ) ||
                        "https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/hand-to-heart/combined.mp3"
                      }
                      title="Anxiety : Hand to heart : Long"
                    />
                  ),
                },
                {
                  title: "Short version",
                  audioMp3: (
                    <ReactAudioPlayer
                      audioURL={
                        getBlobURL(
                          localCollection.audio?.handToHeartShort,
                          "audio"
                        ) ||
                        "https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/short-hand-to-heart/combined.mp3"
                      }
                      title="Anxiety : Hand to heart : Short"
                    />
                  ),
                },
              ],
              video: [
                {
                  title: "Hand to heart",
                  handToHeart: (
                    <ReactVideoPlayer
                      videoURL={
                        getBlobURL(
                          localCollection.video?.handToHeart,
                          "video"
                        ) ||
                        "https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/hand-to-heart/h2h-caricature.mp4"
                      }
                      title="Anxiety : Hand to heart"
                    />
                  ),
                },
              ],
            },
          ],
        },
        {
          title: "Permissions for Anxiety",
          image:
            "/app/images/permission-to-reset-anxiety/reset-anxiety-permissions.jpg",
          content: [
            {
              audio: [
                {
                  title: "Being present in my body and grounded",
                  audioMp3: (
                    <ReactAudioPlayer
                      audioURL={
                        getBlobURL(
                          localCollection.audio?.presentAndGrounded,
                          "audio"
                        ) ||
                        "https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/anxiety/permissions/being-present-in-my-body-and-grounded/combined.mp3"
                      }
                      title="Anxiety : Permissions for Anxiety : Being present in my body and grounded"
                    />
                  ),
                },
                {
                  title: "Feeling supported",
                  audioMp3: (
                    <ReactAudioPlayer
                      audioURL={
                        getBlobURL(
                          localCollection.audio?.feelingSupported,
                          "audio"
                        ) ||
                        "https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/anxiety/permissions/feeling-supported/combined.mp3"
                      }
                      title="Anxiety : Permissions for Anxiety : Feeling supported"
                    />
                  ),
                },
                {
                  title: "Balance and a healthy mindset",
                  audioMp3: (
                    <ReactAudioPlayer
                      audioURL={
                        getBlobURL(
                          localCollection.audio?.balanceHealthyMindset,
                          "audio"
                        ) ||
                        "https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/anxiety/permissions/balance-and-a-healthy-mindset/combined.mp3"
                      }
                      title="Anxiety : Permissions for Anxiety : Balance and a healthy mindset"
                    />
                  ),
                },
                {
                  title: "Confidence in decisison making",
                  audioMp3: (
                    <ReactAudioPlayer
                      audioURL={
                        getBlobURL(
                          localCollection.audio?.confidenceDecisionMaking,
                          "audio"
                        ) ||
                        "https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/anxiety/permissions/confidence-in-decision-making/combined.mp3"
                      }
                      title="Anxiety : Permissions for Anxiety : Confidence in decisison making"
                    />
                  ),
                },
                {
                  title: "Having more physical energy",
                  audioMp3: (
                    <ReactAudioPlayer
                      audioURL={
                        getBlobURL(
                          localCollection.audio?.physicalEnergy,
                          "audio"
                        ) ||
                        "https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/anxiety/permissions/having-more-physical-energy/combined.mp3"
                      }
                      title="Anxiety : Permissions for Anxiety : Having more physical energy"
                    />
                  ),
                },
                {
                  title: "Letting go of worries quickly",
                  audioMp3: (
                    <ReactAudioPlayer
                      audioURL={
                        getBlobURL(
                          localCollection.audio?.lettingGoWorries,
                          "audio"
                        ) ||
                        "https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/anxiety/permissions/letting-go-of-worries-quickly/combined.mp3"
                      }
                      title="Anxiety : Permissions for Anxiety : Letting go of worries quickly"
                    />
                  ),
                },
                {
                  title: "Feeling safe to relax",
                  audioMp3: (
                    <ReactAudioPlayer
                      audioURL={
                        getBlobURL(
                          localCollection.audio?.safeToRelax,
                          "audio"
                        ) ||
                        "https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/anxiety/permissions/feeling-safe-to-relax/combined.mp3"
                      }
                      title="Anxiety : Permissions for Anxiety : Feeling safe to relax"
                    />
                  ),
                },
                {
                  title: "Increased concentration",
                  audioMp3: (
                    <ReactAudioPlayer
                      audioURL={
                        getBlobURL(
                          localCollection.audio?.increasedConcentration,
                          "audio"
                        ) ||
                        "https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/anxiety/permissions/increased-concentration/combined.mp3"
                      }
                      title="Anxiety : Permissions for Anxiety : Increased concentration"
                    />
                  ),
                },
                {
                  title: "Overcome overthinking",
                  audioMp3: (
                    <ReactAudioPlayer
                      audioURL={
                        getBlobURL(
                          localCollection.audio?.overcomeOverthinking,
                          "audio"
                        ) ||
                        "https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/anxiety/permissions/overcoming-overthinking/combined.mp3"
                      }
                      title="Anxiety : Permissions for Anxiety : Overcome overthinking"
                    />
                  ),
                },
                {
                  title: "Positive behaviours",
                  audioMp3: (
                    <ReactAudioPlayer
                      audioURL={
                        getBlobURL(
                          localCollection.audio?.positiveBehaviours,
                          "audio"
                        ) ||
                        "https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/anxiety/permissions/positive-behaviours/combined.mp3"
                      }
                      title="Anxiety : Permissions for Anxiety : Positive behaviours"
                    />
                  ),
                },
                {
                  title: "Coming out of flight or flight",
                  audioMp3: (
                    <ReactAudioPlayer
                      audioURL={
                        getBlobURL(
                          localCollection.audio?.fightFlight,
                          "audio"
                        ) ||
                        "https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/anxiety/permissions/coming-out-of-fight-or-flight/combined.mp3"
                      }
                      title="Anxiety : Permissions for Anxiety : Coming out of flight or flight"
                    />
                  ),
                },
                {
                  title: "Calming physical anxiety symptoms",
                  audioMp3: (
                    <ReactAudioPlayer
                      audioURL={
                        getBlobURL(
                          localCollection.audio?.physicalAnxiety,
                          "audio"
                        ) ||
                        "https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/pat-program/anxiety/permissions/calming-physical-anxiety-symptoms/combined.mp3"
                      }
                      title="Anxiety : Permissions for Anxiety : Calming physical anxiety symptoms"
                    />
                  ),
                },
              ],
            },
          ],
        },
      ],
    },
  ]

  return fixture
}

export default permToResetAnxietyFixture
