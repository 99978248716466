import React from "react"
import localforage from "localforage"
import { getBlobURL } from "../../functions/utils"

async function qtChakraBalancingFixture() {
  const localCollection = await localforage.getItem("cG9zdDo2MzY1")
  if (!localCollection) return undefined

  const fixture = [
    {
      programName: "Quantum-Touch Chakra Balancing",
      featuredImage: "/app/images/quantum-touch/chakra-balancing.jpg",
      tags: ["quantum-touch"],
      type: "single",
      duration: "29",
      audioMp3: getBlobURL(
        localCollection.audio?.balancing,
        "audio"
      ) || "https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/app/quantum-touch/chakra-balancing.mp3",
    },
  ]
  return fixture
}

export default qtChakraBalancingFixture;