import React from 'react'
import localforage from "localforage"
import { getBlobURL } from "../../functions/utils"

async function qtBalancingStructureFixture() {
  const localCollection = await localforage.getItem("cG9zdDo2MzQ4")
  if (!localCollection) return undefined

  const fixture = [
    {
        programName: "Quantum-Touch Balancing Structure",
        featuredImage: "/app/images/quantum-touch/balancing-structure.jpg",
        tags: ['quantum-touch'],
        files: "5",
        audioFiles: [
            {
                title: "Intro into the heart space",
                image: "/app/images/quantum-touch/balancing-structure.jpg",
                duration: "15",
                audioMp3: getBlobURL(
                  localCollection.audio?.introHeart,
                  "audio"
                ) || "https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/app/quantum-touch/bs-into-the-heart-space.mp3"
            },
            {
                title: "Hips, Occiputs, Sphenoid",
                image: "/app/images/quantum-touch/balancing-structure.jpg",
                duration: "10",
                audioMp3: getBlobURL(
                  localCollection.audio?.hipsOcciputs,
                  "audio"
                ) || "https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/app/quantum-touch/bs-hips-occiputs-sphenoid.mp3"
            },
            {
                title: "Psoas muscle, piriformis, Quadratus Lumborum",
                image: "/app/images/quantum-touch/balancing-structure.jpg",
                duration: "9",
                audioMp3: getBlobURL(
                  localCollection.audio?.psoasMuscle,
                  "audio"
                ) || "https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/app/quantum-touch/bs-psoas-muscle-piriformis-quadratus-lumborum.mp3"
            },
            {
                title: "C1 - C7",
                image: "/app/images/quantum-touch/balancing-structure.jpg",
                duration: "4",
                audioMp3: getBlobURL(
                  localCollection.audio?.c1C7,
                  "audio"
                ) || "https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/app/quantum-touch/bs-c1-c7.mp3"
            },
            {
                title: "Grounding",
                image: "/app/images/quantum-touch/balancing-structure.jpg",
                duration: "15",
                audioMp3: getBlobURL(
                  localCollection.audio?.grounding,
                  "audio"
                ) || "https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/app/quantum-touch/bs-grounding.mp3"
            }
        ]
    }
  ]
  return fixture
}

export default qtBalancingStructureFixture;