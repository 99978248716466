import React from 'react'
import localforage from "localforage"
import { getBlobURL } from "../../functions/utils"

async function qtMorningMeditationFixture() {
  const localCollection = await localforage.getItem("cG9zdDo2MzU4")
  if (!localCollection) return undefined

  const fixture = [
    {
        programName: "Quantum-Touch Morning Meditation",
        featuredImage: "/app/images/quantum-touch/morning-meditation.jpg",
        tags: ['quantum-touch'],
        type: 'single',
        title: "Morning Meditation",
        duration: "12",
        audioMp3: getBlobURL(
          localCollection.audio?.morningMeditation,
          "audio"
        ) || "https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/app/quantum-touch/morning-meditation.mp3"
    }
  ] 
  return fixture
}

export default qtMorningMeditationFixture;