import React from "react"
import localforage from "localforage"
import { getBlobURL } from "../../functions/utils"

async function mindfulnessCollectionFixture() {
  const localCollection = await localforage.getItem("cG9zdDo2OTE0")
  if (!localCollection) return undefined

  const fixture = [
    {
      programName: "The Essential Mindfulness Collection",
      featuredImage: "/app/images/the-essential-mindfulness-collection/",
      desc:
        "This collection has a mindful meditation to suit each intention and bring your back to the present moment, at different times of the day. Simply listen to something that will relax and reset you so easily again and again.",
      audioFiles: [
        {
          title: "Lunchtime Reset",
          image:
            "/app/images/the-essential-mindfulness-collection/lunchtime-reset.jpg",
          duration: "7",
          audioMp3:
            getBlobURL(
              localCollection.audio?.lunchtimeReset,
              "audio"
            ) ||
            "https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/app/gift/lunchtime-reset.mp3",
        },
        {
          title: "Finding your feet",
          image:
            "/app/images/the-essential-mindfulness-collection/finding-your-feet.jpg",
          duration: "13",
          audioMp3:
            getBlobURL(
              localCollection.audio?.findingFeet,
              "audio"
            ) ||
            "https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/app/gift/finding-your-feet.mp3",
        },
        {
          title: "Mindful body scan",
          image:
            "/app/images/the-essential-mindfulness-collection/mindful-body-scan.jpg",
          duration: "16",
          audioMp3:
            getBlobURL(
              localCollection.audio?.mindfulBodyScan,
              "audio"
            ) ||
            "https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/app/gift/mindful-body-scan.mp3",
        },
        {
          title: "From anxiety to inner peace",
          image:
            "/app/images/the-essential-mindfulness-collection/peace-inner.jpg",
          duration: "26",
          audioMp3:
            getBlobURL(
              localCollection.audio?.anxietyInnerPeace,
              "audio"
            ) ||
            "https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/app/gift/from-anxiety-to-inner-peace.mp3",
        },
        {
          title: "Morning daily intention",
          image:
            "/app/images/the-essential-mindfulness-collection/morning-daily-intention.jpg",
          duration: "8",
          audioMp3:
            getBlobURL(
              localCollection.audio?.dailyIntention,
              "audio"
            ) ||
            "https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/app/gift/morning-daily-intention.mp3",
        },
        {
          title: "Easy sleep",
          image:
            "/app/images/the-essential-mindfulness-collection/mindfull-sleep.jpg",
          duration: "19",
          audioMp3:
            getBlobURL(
              localCollection.audio?.easySleep,
              "audio"
            ) ||
            "https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/app/gift/easy-sleep.mp3",
        },
        {
          title: "5 Minute Silent Bell",
          image:
            "/app/images/the-essential-mindfulness-collection/sound-bell.jpg",
          duration: "5",
          audioMp3:
            getBlobURL(
              localCollection.audio?.silentBell5,
              "audio"
            ) ||
            "https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/app/silent-bell-5-minute.mp3",
        },
        {
          title: "Mindful breathing",
          image:
            "/app/images/the-essential-mindfulness-collection/awareness-of-the-breath.jpg",
          duration: "12",
          audioMp3:
            getBlobURL(
              localCollection.audio?.mindfulBreathing,
              "audio"
            ) ||
            "https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/app/gift/awareness-of-the-breath.mp3",
        },
        {
          title: "Cultivating mindfulness",
          image:
            "/app/images/the-essential-mindfulness-collection/mindfull-sleep.jpg",
          duration: "15",
          audioMp3:
            getBlobURL(
              localCollection.audio?.cultivatingMindfulness,
              "audio"
            ) ||
            "https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/app/gift/cultivating-mindfulness.mp3",
        },
        {
          title: "Compassion",
          image:
            "/app/images/the-essential-mindfulness-collection/compassion.jpg",
          duration: "11",
          audioMp3:
            getBlobURL(
              localCollection.audio?.compassion,
              "audio"
            ) ||
            "https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/app/gift/compassion-for-opening-the-heart.mp3",
        },
        {
          title: "15 Minute Silent Bell",
          image:
            "/app/images/the-essential-mindfulness-collection/sound-bell.jpg",
          duration: "15",
          audioMp3:
            getBlobURL(
              localCollection.audio?.silentBell15,
              "audio"
            ) ||
            "https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/app/silent-bell-15-minute.mp3",
        },
        {
          title: "Being in my body",
          image:
            "/app/images/the-essential-mindfulness-collection/being-in-my-body.jpg",
          duration: "21",
          audioMp3:
            getBlobURL(
              localCollection.audio?.beingBody,
              "audio"
            ) ||
            "https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/app/gift/being-in-my-body.mp3",
        },
        {
          title: "Gratitude",
          image:
            "/app/images/the-essential-mindfulness-collection/gratitude.jpg",
          duration: "15",
          audioMp3:
            getBlobURL(
              localCollection.audio?.gratitude,
              "audio"
            ) ||
            "https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/app/gift/gratitude.mp3",
        },
        {
          title: "Activating your senses",
          image:
            "/app/images/the-essential-mindfulness-collection/activating-your-senses.jpg",
          duration: "16",
          audioMp3:
            getBlobURL(
              localCollection.audio?.activatingSenses,
              "audio"
            ) ||
            "https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/app/gift/activating-your-senses.mp3",
        },
        {
          title: "Compassion for me and you",
          image:
            "/app/images/the-essential-mindfulness-collection/compassion-form-me-and-you.jpg",
          duration: "25",
          audioMp3:
            getBlobURL(
              localCollection.audio?.compassionMeYou,
              "audio"
            ) ||
            "https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/app/gift/compassion-for-me-and-you.mp3",
        },
        {
          title: "30 Minute Silent Bell",
          image:
            "/app/images/the-essential-mindfulness-collection/sound-bell.jpg",
          duration: "20",
          audioMp3:
            getBlobURL(
              localCollection.audio?.silentBell30,
              "audio"
            ) ||
            "https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/app/silent-bell-30-minute.mp3",
        },
      ],
    },
  ]

  return fixture
}

export default mindfulnessCollectionFixture