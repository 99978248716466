import React from "react"
import localforage from "localforage"
import { getBlobURL } from "../../functions/utils"

async function qtBrainHealingFixture() {
  const localCollection = await localforage.getItem("cG9zdDo2MzU0")
  if (!localCollection) return undefined

  const fixture = [
    {
        programName: "Quantum-Touch Brain Healing",
        featuredImage: "/app/images/quantum-touch/brain-healing.jpg",
        tags: ['quantum-touch'],
        files: "7",
        audioFiles: [
            {
                title: "Opening your heart chakra healing energy",
                image: "/app/images/quantum-touch/brain-healing.jpg",
                duration: "15",
                audioMp3: getBlobURL(
                  localCollection.audio?.openingHeart,
                  "audio"
                ) || "https://s3.eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/app/quantum-touch/bh-into-the-heart-space.mp3"
            },
            {
                title: "Healing your temporal lobes",
                image: "/app/images/quantum-touch/brain-healing.jpg",
                duration: "4",
                audioMp3: getBlobURL(
                  localCollection.audio?.healingTemporalLobes,
                  "audio"
                ) || "https://s3.eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/app/quantum-touch/bh-temporal-lobes.mp3"
            },
            {
                title: "Healing your pituitary and pineal glands",
                image: "/app/images/quantum-touch/brain-healing.jpg",
                duration: "7",
                audioMp3: getBlobURL(
                  localCollection.audio?.healingGlands,
                  "audio"
                ) || "https://s3.eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/app/quantum-touch/bh-pituitary-and-pineal-glands.mp3"
            },
            {
                title: "Healing your corpus callosum",
                image: "/app/images/quantum-touch/brain-healing.jpg",
                duration: "4",
                audioMp3: getBlobURL(
                  localCollection.audio?.healingCallosum,
                  "audio"
                ) || "https://s3.eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/app/quantum-touch/bh-corpus-callosum.mp3"
            },
            {
                title: "Healing your Amygdala, Hippocampus, Hypothalamus",
                image: "/app/images/quantum-touch/brain-healing.jpg",
                duration: "10",
                audioMp3: getBlobURL(
                  localCollection.audio?.healingAmygdala,
                  "audio"
                ) || "https://s3.eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/app/quantum-touch/bh-amygdala-hippocampus-hypothalamus.mp3"
            },
            {
                title: "Awakening your pleasure centers and reward Circuits",
                image: "/app/images/quantum-touch/brain-healing.jpg",
                duration: "5",
                audioMp3: getBlobURL(
                  localCollection.audio?.pleasureCenter,
                  "audio"
                ) || "https://s3.eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/app/quantum-touch/bh-reward-circuit.mp3"
            },
            {
                title: "Bathing your entire brain in love",
                image: "/app/images/quantum-touch/brain-healing.jpg",
                duration: "6",
                audioMp3: getBlobURL(
                  localCollection.audio?.bathingBrain,
                  "audio"
                ) || "https://s3.eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/app/quantum-touch/bh-brain-blast.mp3"
            }
        ]
    }
  ]
  return fixture
}

export default qtBrainHealingFixture;