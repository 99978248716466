import localforage from "localforage";
import permToSleepFixture from "../fixtures/localFixtures/permission-to-sleep";
import resetAnxietyFixture from "../fixtures/localFixtures/permission-to-reset-anxiety";
import mindfulnessCollectionFixture from "../fixtures/localFixtures/mindfulness-collection";
import chakraBalancingFixture from "../fixtures/localFixtures/chakra-balancing";
import qtMorningMeditationFixture from "../fixtures/localFixtures/qt-morning-meditation";
import qtBrainHealingFixture from "../fixtures/localFixtures/qt-brain-healing";
import qtBalancingStructureFixture from "../fixtures/localFixtures/qt-balancing-structure";
import qtChakraBalancingFixture from "../fixtures/localFixtures/qt-chakra-balancing";

export function blobToArrayBuffer(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener("loadend", (e) => {
      resolve(reader.result);
    });
    reader.addEventListener("error", reject);
    reader.readAsArrayBuffer(blob);
  });
}

export function arrayBufferToBlob(buffer, type) {
  return new Blob([buffer], { type: type });
}

export async function getItem(key, fileType) {
  const blob = await localforage.getItem(key);
  return {
    data: blob,
    url: URL.createObjectURL(blob),
    type: blob.type,
  };
}

export function getBlobURL(blob, type) {
  try {
    return URL.createObjectURL(blob);
  } catch {
    return undefined;
  }
}

export async function getFixtureFunc(id) {
  switch(id) {
    case 'cG9zdDo3NzY5':
      const permToSleep = await permToSleepFixture()
      return permToSleep
    case 'cG9zdDo3MjM0':
      const resetAnxiety = await resetAnxietyFixture()
      return resetAnxiety
    case 'cG9zdDo2OTE0':
      const esmCollection = await mindfulnessCollectionFixture()
      return esmCollection
    case 'cG9zdDo2OTcx':
      const chakraBalancingCollection = await chakraBalancingFixture()
      return chakraBalancingCollection
    case 'cG9zdDo2MzU4':
      const qtMorningMeditation = await qtMorningMeditationFixture()
      return qtMorningMeditation
    case 'cG9zdDo2MzU0':
      const qtBrainHealing = await qtBrainHealingFixture()
      return qtBrainHealing
    case 'cG9zdDo2MzQ4':
      const qtBalancingStructure = await qtBalancingStructureFixture()
      return qtBalancingStructure
    case 'cG9zdDo2MzY1':
      const qtChakraBalancing = await qtChakraBalancingFixture()
      return qtChakraBalancing
    case 'cG9zdDo3NzQz':
      const selfHealingMastery = await localforage.getItem('cG9zdDo3NzQz')
      return selfHealingMastery
    default:
      return undefined;
  }
}